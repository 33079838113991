<template>
  <v-app>
    <v-app-bar flat>
      <v-row no-gutters class="align-center mx-auto container">
        <v-icon
          icon="$menu"
          class="v-col-2 d-lg-none d-flex justify-center"
          color="#fff"
          size="large"
          aria-label="menu"
          @click.stop="drawer = !drawer"
        ></v-icon>

        <v-col cols="6" md="7" lg="3" xl="2" class="d-flex justify-center">
          <ImageLogo />
        </v-col>

        <v-col cols="7" md="7" lg="7" xl="8" class="d-none d-lg-block">
          <Menu :menu="page.menu" class="d-flex" :nav="true" width="100%" />
        </v-col>

        <v-col cols="4" md="3" lg="2" xl="2" class="d-flex justify-center">
          <Button :download-nick="nick" :software-title="soft" :text="page.downloadTitle" />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary location="top" class="d-lg-none d-flex" absolute>
      <Menu :menu="page.menu" class="mt-4" />
    </v-navigation-drawer>

    <v-main v-scroll="onScroll" class="pt-0 mx-auto w-100">
      <div v-if="page.breadcrumbs && page.breadcrumbs.length > 0" class="breadcrumbs">
        <breadcrumbs
          :items="page.breadcrumbs"
          devider-color="#f4c146"
          devider-icon="$chevronDoubleRight"
          class="py-0 ma-auto container"
        />
      </div>
      <aside v-if="page.blocks?.AboveContent" class="above-content my-0">
        <Region :key="page.uri" region="AboveContent" :blocks="page.blocks.AboveContent" class="container" />
      </aside>

      <v-row v-if="page.blocks?.RightSide" no-gutters class="container mx-auto">
        <v-col cols="12" md="9">
          <article class="mr-2 mt-2 pa-5">
            <Article :key="page.uri" class="ma-2 pa-3">
              <template
                v-if="page.typename === 'BrandReview' || page.typename === 'SlotsGameReview'"
                #article-image-button
              >
                <ArticlePassport />
              </template>
              <template #article-button></template>
            </Article>

            <Button
              :download-nick="nick"
              :software-title="soft"
              :text="page.downloadTitle"
              class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
            />
          </article>
          <aside v-if="page.blocks?.BelowContent" class="blockDesign mr-2 mt-2 pa-5 belowContent">
            <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
          </aside>
        </v-col>

        <aside v-if="page.blocks?.RightSide" class="v-col-12 v-col-md-3">
          <Region
            :key="page.uri"
            region="RightSide"
            :blocks="page.blocks.RightSide"
            class="blockDesign rightBlock mb-2"
            @noregion="page.hideRegion"
          />
        </aside>
      </v-row>
      <template v-else>
        <article class="container mx-auto">
          <Article :key="page.uri" class="ma-2 pa-3">
            <template
              v-if="page.typename === 'BrandReview' || page.typename === 'SlotsGameReview'"
              #article-image-button
            >
              <ArticlePassport />
            </template>
          </Article>
          <Button
            :download-nick="nick"
            :software-title="soft"
            :text="page.downloadTitle"
            class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
          />
        </article>
        <aside v-if="page.blocks?.BelowContent" class="blockDesign belowContent">
          <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" class="container" />
        </aside>
      </template>
      <footer v-if="page.blocks?.Footer" class="d-block">
        <Region :key="page.uri" region="Footer" :blocks="page.blocks.Footer" />
      </footer>
      <v-sheet v-show="fab" :key="page.uri" class="footer bg-white">
        <snack-bar rounded="xl" />
      </v-sheet>
    </v-main>
  </v-app>
</template>
<script setup>
import SnackBar from "@/components2/lo/casinoofthekings/SnackBar";
import ArticlePassport from "@/components2/lo/casinoaus/ArticlePassport";
const page = inject("page");
const nick = ref(unref(page)?.rels?.downloadNick);
const soft = ref(unref(page)?.rels?.softwareTitle);
const drawer = ref(false);
// const { makeAttrs, makeClick } = useLead(page);
watchEffect(() => {
  nick.value = unref(page)?.rels?.downloadNick;
  soft.value = unref(page)?.rels?.softwareTitle;
});
const fab = ref(false);
const onScroll = e => {
  if (typeof window === "undefined") return;
  const top = window.scrollY || e.target.scrollTop || 0;
  fab.value = top > 20;
};
</script>
