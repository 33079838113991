<template>
  <v-row class="ma-auto">
    <v-col
      v-if="welcomeBonus"
      class="font-weight-bold text-center text-yellowColor text-h6"
      cols="12"
      md="4"
      lg="4"
      xl="4"
    >
      {{ welcomeBonus }}
    </v-col>
    <v-col v-if="rating && welcomeBonus" cols="12" md="4" lg="4" xl="4" class="text-center">
      <Rating v-model="rating"></Rating>
    </v-col>
    <v-col
      v-if="nick || soft"
      cols="12"
      sm="12"
      :md="rating && welcomeBonus ? '4' : '12'"
      :lg="rating && welcomeBonus ? '4' : '12'"
      class="text-center"
    >
      <Button
        :download-nick="nick"
        :software-title="soft"
        text="Play Now"
        class="w-50 text-uppercase font-weight-bold"
      />
    </v-col>
    <!-- <v-col cols="12" md="4" :lg="welcomeBonus ? '2' : nick || soft ? '2' : '12'" class="text-right"> -->
    <ScrollToTop class="up" v-bind="$attrs" />
    <!-- </v-col> -->
  </v-row>
</template>
<script setup>
const page = inject("page");
const rels = unref(page)?.rels;
const nick = rels?.downloadNick || "";
const soft = rels?.softwareTitle || "";
const rating = rels?.Popularity || 1;
const welcomeBonus = _.get(unref(page), ["rels", "Welcome Bonus", "title"], "");
</script>
